import SolutionManagement from '../components/marketplace-account/my-solutions/solution-management/SolutionManagement';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withCommand from './withCommand';
/*import withCommand from "./withCommand";*/

const appSubmissionFragment = gql`
  fragment SolutionManagementModel on Solution {
    id
    developerId
  }
`;

export const getSolutionQuery = gql`
  query($solutionId: ID) {
    solutionList(id: $solutionId) {
      id
      listingVersion
      worksWithIds
      apigee_id
      developerId
      developerName
      developerAgreement
      dnpAppName
      certificationStatus
      publisherAgreementDetails {
        status
        entityId
        entity
        acceptedBy
        acceptedByEmail
      }
      orgId
      status
      submittedOn
      lastUpdatedBy
      lastUpdatedOn
      registeredOn
      solutionType
      solution_visibility {
        solutionVisibilityType
        privateSolutionUsers
      }
      app_subscriptions {
        appSubscriptionsType
      }
      overview {
        name
        category
        applicationArea
        version
        briefDescription
        domain
        detailedDescription
        publisherName
        website
        solutionWebsite
        outageNotificationsEmail
        copyright
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
        searchKeywords
        videos {
          id
          title
          url
          description
        }
        featuredImage
        galleryImages {
          id
          galleryImage
          imagePositionIndex
        }
      }
      highlights {
        highlight_list {
          id
          description
        }
      }
      support {
        tutorials_and_documentation {
          id
          title
          url
          purpose
        }
        supportMessage
        supportCenterUrl
        phoneNumber
        emailAddress
        supportCommunityUrl
      }
      features {
        id
        title
        description
        featureImage
      }
      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      provisioning_interface {
        endpointUrl
        redirectURL
        provisioningtype
        entityID
        subscriptionID
      }
      subscription_notifications {
        email
        endpointUrl
        apiNotification
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      registration_details {
        api_details {
          id
          name
          displayName
          description
          isOptional
          apiType
          asyncIntegrationId
          providers {
            id
            entityId
            name
          }
        }
        apiKey
        apiSecret
        keyIssuedOn
        keyExpiresOn
        callbackUrl
      }
      partner_program_registration_details {
        partner_api_details {
          id
          applicationName
          description
          dataProvider
        }
      }
      address {
        street_address
        city
        country
        countrySubDivisionCode
        postalCode
      }
      contract_notifications {
        contractNotificationsType
      }
      payment_info {
        paymentMode
        paymentOption {
          stripe
          cdk
        }
      }
      activateOnly
      appPublishingFee
      stripePriceId
    }
  }
`;

export const solutionlisting = gql`
  mutation($formFields: AddSolutionListingCmd) {
    addSolutionListing(command: $formFields) {
      ...SolutionManagementModel
    }
  }
  ${appSubmissionFragment}
`;

export const updateSolutionStatusQuery = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String
    $lastUpdatedBy: String
    $lastUpdatedOn: String
    $comments: [CommentCmd]
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
  ) {
    updateSolutionStatus(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        lastUpdatedBy: $lastUpdatedBy
        lastUpdatedOn: $lastUpdatedOn
        comments: $comments
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
      }
    ) {
      id
      listingVersion
      status
      error {
        message
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  dataRefetch: () => data.refetch(),
  solutions: data.solutionList ? data.solutionList : null,
  remittanceInfo: data.getRemittanceInfo ? data.getRemittanceInfo : null,
  loading: data.loading
});

export default compose(
  graphql(getSolutionQuery, {
    props: mapDataToProps,
    options: ({ match }) => ({
      variables: {
        solutionId: match.params.id,
        type: match.params.type
      }
    })
  }),
  compose(
    graphql(solutionlisting, {
      props: ({ mutate }) => ({
        solutionListing: formfields =>
          mutate({
            variables: {
              formFields: formfields
            }
          })
      })
    }),
    withCommand({ name: 'solutionListing' })
  ),
  compose(
    graphql(updateSolutionStatusQuery, {
      props: ({ mutate }) => ({
        updateSolutionStatus: (
          id,
          listingVersion,
          status,
          lastUpdatedBy,
          lastUpdatedOn,
          comments,
          developerEmail,
          orgId,
          appName,
          attributeName,
          attributeValue
        ) =>
          mutate({
            variables: {
              id: id,
              listingVersion: listingVersion,
              status: status,
              lastUpdatedBy: lastUpdatedBy,
              lastUpdatedOn: lastUpdatedOn,
              comments: comments,
              developerEmail: developerEmail,
              orgId: orgId,
              appName: appName,
              attributeName: attributeName,
              attributeValue: attributeValue
            }
          })
      })
    }),
    withCommand({ name: 'updateSolutionStatus' })
  )
)(SolutionManagement);
