import React, { useState, useEffect } from 'react';
import { TextField } from '@cdk-uip/react-text-field';
import { BUTTON_SIZES, IconAdd, IconButton } from 'cdk-radial';
import { ChipSet, Chip, ChipText } from '@cdk-uip/react-chips';
import { Radio } from '@cdk-uip/react-radio';

import AdditionalInfoConstants from './AdminControlAdditionalDetailsConstants';

const VISION_CASE_QUEUE_DEFAULT_VALUE =
  AdditionalInfoConstants.VISION_CASE_QUEUE_DEFAULT_VALUE;

const UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE =
  AdditionalInfoConstants.UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE;

const AdminControlAdditionalInfo = props => {
  const catalog_number = props.formFieldValues.catalog_number || '';
  const catalog_number_array = catalog_number ? catalog_number.split(',') : [];
  const chipsToShow =
    catalog_number_array.length > 11 ? 11 : catalog_number_array.length;
  const is_vision_case_required =
    props.formFieldValues.is_vision_case_required || false;
  const vision_case_queue = props.formFieldValues.vision_case_queue || '';
  const uninstall_queue = props.formFieldValues.uninstall_queue || '';

  const [inputNumber, setInputNumber] = useState('');
  const [catalogNumbers, setCatalogNumbers] = useState(catalog_number_array);
  const [showMore, setShowMore] = useState(false);
  const [numberOfChips, setNumberOfChips] = useState(chipsToShow);
  const [isVisonCaseRequiredState, setIsVisionCaseRequired] = useState(
    is_vision_case_required
  );
  const [visionCaseQueueState, setVisionCaseQueue] = useState(
    vision_case_queue
  );
  const [uninstallQueue, setUninstallQueue] = useState(uninstall_queue);

  const handleRadioChange = (event, val) => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.is_vision_case_required = val;
    setIsVisionCaseRequired(val);
    if (val) {
      formFieldValues.vision_case_queue = VISION_CASE_QUEUE_DEFAULT_VALUE;
      formFieldValues.uninstall_queue = UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE;
      setVisionCaseQueue(VISION_CASE_QUEUE_DEFAULT_VALUE);
      setUninstallQueue(UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE);
    }
  };

  const handleOnVisionCaseQueueChange = event => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.vision_case_queue = event.target.value;
    setVisionCaseQueue(event.target.value);
  };

  const handleOnUninstallQueueChange = event => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.uninstall_queue = event.target.value;
    setUninstallQueue(event.target.value);
  };
  const handleOnInputNumberChange = event => {
    const regEx = /^[0-9]*$/;
    if (regEx.test(event.target.value)) {
      setInputNumber(event.target.value);
    }
  };

  const handleAddPress = () => {
    if (!catalogNumbers.includes(inputNumber)) {
      setCatalogNumbers([...catalogNumbers, inputNumber]);
    }
    setInputNumber('');
  };

  const onChipDelete = (index, chipValue) => {
    setCatalogNumbers(catalogNumbers.filter((chip, i) => i !== index));
  };

  useEffect(() => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.catalog_number = catalogNumbers.join(',');
    if (showMore && catalogNumbers.length === 11) setShowMore(false);
  }, [catalogNumbers]);

  useEffect(() => {
    setNumberOfChips(showMore ? catalogNumbers.length : 11);
  }, [showMore, catalogNumbers]);

  return (
    <div className="admin-control-additional-info-container">
      <div className="catalog-number-info-container additional-info-control-item-div">
        <label className="additional-info-control-item-label">
          Catalog Number(s)
        </label>
        <div className="catalog-number-textfield">
          <TextField
            value={inputNumber}
            onChange={handleOnInputNumberChange}
            disabled={props.fieldProperties.disabled}
            maxLength={AdditionalInfoConstants.MaxLengthOfCatalogNumber}
          />
          {catalogNumbers.includes(inputNumber) && (
            <div
              style={{ color: 'black' }}
              className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
            >
              Catalog Number already added
            </div>
          )}
        </div>
        <div className="catalog-number-text-end">
          <div className="catalog-number-add-button">
            <IconButton
              icon={<IconAdd />}
              size={BUTTON_SIZES.SMALL}
              text="Button"
              onClick={handleAddPress}
              isDisabled={
                catalogNumbers.includes(inputNumber) || inputNumber.length === 0
              }
            />
          </div>
          <div className="character-remaining-div">
            {inputNumber ? inputNumber.length : 0} /{' '}
            {AdditionalInfoConstants.MaxLengthOfCatalogNumber}
          </div>
        </div>
      </div>
      <div className="catalog-number-chipset-container">
        {catalogNumbers.length ? (
          <ChipSet className="catalog-number-input-chipset">
            {catalogNumbers.slice(0, numberOfChips).map((chipText, index) => (
              <Chip key={index}>
                <ChipText>{chipText}</ChipText>
                <span
                  className="material-icons"
                  style={{ marginLeft: '5px', color: 'gray' }}
                  onClick={() => {
                    onChipDelete(index, chipText);
                  }}
                >
                  cancel
                </span>
              </Chip>
            ))}
            {catalogNumbers.length > 11 && (
              <p
                className="catalog-number-show-more"
                onClick={() => setShowMore(true)}
              >
                {!showMore && `+ ${catalogNumbers.length - 11} more`}
              </p>
            )}
          </ChipSet>
        ) : (
          <div style={{ marginLeft: '48%' }}>No catalog number added yet</div>
        )}
      </div>

      <div className="vision-case-confirmation-container additional-info-control-item-div">
        <label>Is Salesforce Case Required ?</label>
        <div className="radio-box-container-for-vison-case">
          <Radio
            className="radio-item-radio"
            checked={isVisonCaseRequiredState === true}
            disabled={props.fieldProperties.disabled}
            onChange={event => {
              handleRadioChange(event, true);
            }}
          />
          <label className="radio-item-label">{'Yes'}</label>

          <Radio
            className="radio-item-radio"
            checked={isVisonCaseRequiredState === false}
            disabled={props.fieldProperties.disabled}
            onChange={event => {
              handleRadioChange(event, false);
            }}
          />
          <label className="radio-item-label">{'No'}</label>
        </div>
      </div>

      {isVisonCaseRequiredState && (
        <>
          <div className="vision-case-queue-info-container additional-info-control-item-div">
            <label className="additional-info-control-item-label">
              Install Queue
            </label>
            <div className="vision-case-queue-textfield">
              <TextField
                value={visionCaseQueueState}
                onChange={handleOnVisionCaseQueueChange}
                disabled={props.fieldProperties.disabled}
              />
            </div>
          </div>
          <div className="vision-case-queue-info-container additional-info-control-item-div">
            <label className="additional-info-control-item-label">
              Uninstall Queue
            </label>
            <div className="vision-case-queue-textfield">
              <TextField
                value={uninstallQueue}
                onChange={handleOnUninstallQueueChange}
                disabled={props.fieldProperties.disabled}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminControlAdditionalInfo;
