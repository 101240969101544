import SolutionDetailsScreen from '../components/solution-details/SolutionDetailsScreen';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const queryUpdated = gql`
  query($entityId: ID, $solutionId: ID, $listingVersion: String) {
    solutionCatalogList(id: $solutionId, listingVersion: $listingVersion) {
      id
      listingVersion
      developerName
      orgId
      solutionType
      status
      submittedOn
      lastUpdatedOn
      solutionType
      extensibilityType
      oneToOneUI
      activateOnly
      solution_visibility {
        solutionVisibilityType
        privateSolutionUsers
      }
      overview {
        name
        category
        applicationArea
        version
        briefDescription
        detailedDescription
        publisherName
        website
        solutionWebsite
        outageNotificationsEmail
        copyright
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
        videos {
          id
          title
          url
          description
        }
        featuredImage
        galleryImages {
          id
          galleryImage
          imagePositionIndex
        }
      }
      highlights {
        highlight_list {
          id
          description
        }
      }
      support {
        tutorials_and_documentation {
          id
          title
          url
          purpose
        }
        supportMessage
        supportCenterUrl
        phoneNumber
        emailAddress
        supportCommunityUrl
      }
      features {
        id
        title
        description
        featureImage
      }
      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      listingVersionUpdates {
        description
        updates {
          id
          updateText
        }
      }
      provisioning_interface {
        provisioningtype
        endpointUrl
        redirectURL
      }
      subscription_notifications {
        email
        endpointUrl
        apiNotification
      }
      registration_details {
        api_details {
          id
          name
          displayName
          description
          apiType
          asyncIntegrationId
          providers {
            id
            entityId
            name
          }
        }
      }
      partner_program_registration_details {
        partner_api_details {
          id
          applicationName
          description
          dataProvider
        }
      }
      error {
        message
      }
      subscription_management {
        allowSubscriptions
      }
    }
    getRatingStatistics(solutionId: $solutionId) {
      averageRating
      numberOfReviews
    }
    solutionSubscriptions(entityId: $entityId) {
      id
      solutionId
      status
      dateOfPurchase
      subscriptionStartDate
      subscriptionEndDate
      plan {
        id
        planName
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  solution: data.solutionCatalogList ? data.solutionCatalogList[0] : null,
  averageRating:
    data.getRatingStatistics && data.getRatingStatistics.averageRating
      ? data.getRatingStatistics.averageRating
      : null,
  numberOfReviews:
    data.getRatingStatistics && data.getRatingStatistics.numberOfReviews
      ? data.getRatingStatistics.numberOfReviews
      : null,
  existingSubscriptionByEntity:
    data.solutionSubscriptions &&
    data.solutionSubscriptions.length > 0 &&
    data.solutionCatalogList &&
    data.solutionSubscriptions.filter(
      sub => sub.solutionId === data.solutionCatalogList[0].id
    ).length
      ? data.solutionSubscriptions.filter(
          sub => sub.solutionId === data.solutionCatalogList[0].id
        )[0]
      : null,
  loading: data.loading
});

export default compose(
  graphql(queryUpdated, {
    props: mapDataToProps,
    options: ({ match, entity, listingVersion, location, isOldUrl }) => ({
      variables: {
        entityId: entity.id,
        solutionId: isOldUrl
          ? match.params.id
          : new URLSearchParams(location && location.search).get('id'),
        listingVersion: listingVersion,
        solutionIdString: match.params.id
      }
    })
  })
)(SolutionDetailsScreen);
