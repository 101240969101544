export const config = {
  formId: 'additional_info',
  //formTitle: 'Additional Info for the App',
  formTitle: 'Catalog & Salesforce Setup',
  formValueType: 'Object',
  formNote:
    'By filling the following information you can control the oracle contract and salesforce case feature',
  tabs: [
    {
      id: 'additional_info',
      // label: 'Additional Info',
      label: 'Catalog & Salesforce Setup',
      contents: [
        {
          id: 'additional_info_contents',
          fields: [
            {
              name: 'additional_info',
              label: 'Addiional options related to app',
              disabled: false,
              type: 'adminControlAdditionalInfo',
              style: 'additional-info-style',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    additional_info: {
      catalog_number: '',
      is_vision_case_required: false,
      vision_case_queue: '',
      uninstall_queue: ''
    }
  },
  activeTabId: 'additional_info'
};
