import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PreviewSolution from '../components/common/PreviewSolution';

export const queryUpdated = gql`
  query($solutionId: ID, $listingVersion: String) {
    solutionPreviewDetails(id: $solutionId, listingVersion: $listingVersion) {
      id
      listingVersion
      activateOnly
      developerId
      developerName
      status
      solutionType
      submittedOn
      lastUpdatedOn
      oneToOneUI
      solution_visibility {
        solutionVisibilityType
        privateSolutionUsers
      }
      overview {
        name
        category
        applicationArea
        version
        briefDescription
        detailedDescription
        domain
        publisherName
        website
        solutionWebsite
        outageNotificationsEmail
        copyright
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
        videos {
          id
          title
          url
          description
        }
        featuredImage
        galleryImages {
          id
          galleryImage
          imagePositionIndex
        }
      }
      highlights {
        highlight_list {
          id
          description
        }
      }
      support {
        tutorials_and_documentation {
          id
          title
          url
          purpose
        }
        supportMessage
        supportCenterUrl
        phoneNumber
        emailAddress
        supportCommunityUrl
      }
      features {
        id
        title
        description
        featureImage
      }
      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      listingVersionUpdates {
        description
        updates {
          id
          updateText
        }
      }
      solutionType
      registration_details {
        api_details {
          id
          name
          displayName
          description
          apiType
          asyncIntegrationId
          providers {
            id
            entityId
            name
          }
        }
      }
      partner_program_registration_details {
        partner_api_details {
          id
          applicationName
          description
          dataProvider
        }
      }
      subscription_management {
        allowSubscriptions
      }
      data_rights {
        captureDataRightsConsent
      }
    }
    getRatingStatistics(solutionId: $solutionId) {
      averageRating
      numberOfReviews
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  dataRefetch: () => data.refetch(),
  solution: data.solutionPreviewDetails ? data.solutionPreviewDetails[0] : {},
  averageRating:
    data.getRatingStatistics && data.getRatingStatistics.averageRating
      ? data.getRatingStatistics.averageRating
      : null,
  numberOfReviews:
    data.getRatingStatistics && data.getRatingStatistics.numberOfReviews
      ? data.getRatingStatistics.numberOfReviews
      : null,
  loading: data.loading
});

export default compose(
  graphql(queryUpdated, {
    props: mapDataToProps,
    options: ({ match, listingVersion }) => ({
      variables: {
        solutionId: match.params.id,
        listingVersion: listingVersion
      }
    })
  })
)(PreviewSolution);
