import React, { useState, useEffect } from 'react';
import Spinner from '../../../../common/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';
import EmptyContentImage from '../../../../common/images/Empty State 2x.png';
import {
  CardBody,
  CardTitle,
  CardRow,
  CardGroup,
  CardWrapper,
  SecondaryButton,
  PrimaryButton,
  IconEdit,
  BUTTON_VARIANTS,
  IconDelete,
  IconStatusWarningExclamation,
  TOAST_VISIBILITY_DURATIONS,
  TOAST_VARIANTS,
  Toast,
  TOAST_POSITIONS,
  Dialog
} from 'cdk-radial';
import { FormattedMessage } from 'react-intl';
import LearnMore from '../../../../common/LearnMore/LearnMore';
import PaymentCheckoutDialog from '../../../../solution-submission/PaymentCheckoutDialog';
import {
  deleteUnVerifiedBankAccount,
  getPaymentInfoBySolutionId
} from '../../../../../redux/connectedComponents/paymentEdit/paymentsInfo.slice';
import VerifyAccount from './verifyAccount/VerifyAccount';
import PaymentInfoConstants from '../../../../admin-account/solution-moderation/PaymentInfoConstants';
import { getPaymentSetting } from '../../../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';

const DOCS_URL = `${
  environmentURLs.docs
}docs/tutorials/app-lifecycle/app-listing-fee/`;

const NO_AUTOPAY_MSG = [
  'No Payment Method is available for this App, as it is either not required or is not listed.',
  <>
    Payment Method is applicable only when you{' '}
    <a href={DOCS_URL} style={{ color: '#904778' }} target="_blank">
      Publish the App
    </a>{' '}
    with a monthly recurring listing fee.
  </>
];

const PaymentType = {
  BANK: 'stripe_bank',
  CARD: 'stripe_card'
};

export default function PaymentMethods(props) {
  const dispatch = useDispatch();
  const stripeSubscriptionSliceData = useSelector(
    state => state.stripeSubscriptionData
  );
  const { stripeSubscriptionData, loading } = stripeSubscriptionSliceData.data;
  const paymentSliceData = useSelector(state => state.paymentsInfo);
  const {
    paymentsInfo,
    paymentsInfoLoading,
    deleteResponse,
    deletionPending
  } = paymentSliceData;
  const verifyAccountSliceData = useSelector(state => state.verifyAccount);
  const { paymentSettingData } = verifyAccountSliceData.data;
  const bankAppsSliceData = useSelector(state => state.bankAppsDetails);
  const [refresh, setRefresh] = useState(false);
  const { bankApps, bankAppsLoading } = bankAppsSliceData;
  const solution = props.solutions[props.solutions.length - 1];
  const countryCode = props.entity.address?.countryCode;
  const [toastContent, setToastContent] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDeleteBank = () => {
    dispatch(
      deleteUnVerifiedBankAccount({
        token: props?.accessToken,
        solnId: solution?.id,
        orgId: solution?.orgId
      })
    );
    setIsDialogOpen(false);
    setShowToast(true);
    setToastContent(PaymentInfoConstants.DELETEBANKTOAST);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    dispatch(
      getPaymentInfoBySolutionId({
        token: props.accessToken,
        solnId: solution?.id
      })
    );
    dispatch(
      getPaymentSetting({
        email: props.userData.email,
        countryCode: countryCode,
        token: props?.accessToken
      })
    );
  }, [refresh, deletionPending]);

  return (
    <>
      {showToast && (
        <Toast
          content={toastContent}
          position={TOAST_POSITIONS.FIXED}
          style={{ marginTop: '70px', zIndex: '5' }}
          variant={'positive'}
          visibilityDuration={TOAST_VISIBILITY_DURATIONS.ELEVEN_SECONDS}
          onVisibilityDurationEnd={() => {
            setShowToast(false);
          }}
          actions={[
            {
              onClick: () => {
                setShowToast(false);
              },
              text: 'Dismiss'
            }
          ]}
        />
      )}

      <Dialog
        buttonsProps={[
          {
            onClick: handleClose,
            text: 'Cancel',
            variant: BUTTON_VARIANTS.SECONDARY
          },
          {
            onClick: handleDeleteBank,
            text: 'Delete',
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        isOpen={isDialogOpen}
        onClose={handleClose}
        title="Delete Bank Account"
        className="delete_bank_dialog"
      >
        <span>Are you sure you want to delete this Bank Account?</span>
      </Dialog>

      <div className="api-info-subtitle">
        <FormattedMessage
          id="PaymentMethod.helpText"
          defaultMessage={`View the payment details used to publish and list your app in Marketplace. This payment method is charged a monthly recurring listing fee as applicable.`}
        />
      </div>
      <br />
      {(solution.activateOnly !== false && loading) ||
      paymentsInfoLoading ||
      deletionPending ? (
        <div className="payment_loading">
          <Spinner />
          <div>Loading ...</div>
        </div>
      ) : stripeSubscriptionData &&
        stripeSubscriptionData.length &&
        paymentsInfo &&
        paymentsInfo.length ? (
        <PaymentMethodCard
          {...stripeSubscriptionData[0]}
          countryCode={countryCode}
          bankApps={bankApps}
          paymentsInfo={paymentsInfo}
          paymentSettingData={paymentSettingData}
          solution={solution}
          setRefresh={setRefresh}
          refresh={refresh}
          setShowToast={setShowToast}
          setToastContent={setToastContent}
          setIsDialogOpen={setIsDialogOpen}
          {...props}
        />
      ) : (
        <CustomEmptyContent message={NO_AUTOPAY_MSG} />
      )}
    </>
  );
}

const PaymentMethodCard = props => {
  const {
    next_payment_date,
    countryCode,
    bankApps,
    paymentsInfo,
    paymentSettingData,
    solution,
    setRefresh,
    refresh,
    setShowToast,
    setToastContent,
    setIsDialogOpen
  } = props;
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [isVerifyOpen, setIsVerifyOpen] = useState(false);

  const handleClose = statusObj => {
    const { status, message } = statusObj;
    if (status === '200' || status === 200) {
      setShowToast(true);
      setShowEdit(false);
      setToastContent(message);
    } else if (status === PaymentInfoConstants.DECLINED) {
      setShowEdit(false);
    }
    setRefresh(!refresh);
  };

  const onPaymentUpdateSuccess = statusObj => {
    const { status, message } = statusObj;
    if (status === '200' || status === 200) {
      setShowToast(true);
      setRefresh(!refresh);
      setToastContent(message);
    }
  };
  const currencyCode = paymentsInfo[0]?.address?.country;

  const activePaymentMethod =
    paymentsInfo &&
    paymentsInfo?.filter(payment => payment.payment_method_status === 'active');
  const pendingPaymentMethod =
    paymentsInfo &&
    paymentsInfo?.filter(
      payment => payment.payment_method_status === 'pending'
    );
  return (
    <>
      <CardWrapper>
        <CardBody style={{ padding: '32px' }}>
          <CardTitle
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className="payment-method-card-title">
              <div className="">
                {activePaymentMethod[0]?.payment_type === PaymentType.BANK
                  ? 'Bank Account'
                  : 'Credit Card'}
              </div>
            </div>
            {Array.isArray(paymentsInfo) && paymentsInfo.length === 1 && (
              <div>
                <SecondaryButton
                  icon={<IconEdit />}
                  isIconTrailing
                  text="Edit"
                  onClick={() => setShowEdit(true)}
                />
              </div>
            )}
          </CardTitle>
          <CardRow
            style={{
              border: '1px solid',
              color: 'rgba(196, 198, 200, 1)',
              marginTop: '10px'
            }}
          />
          <CardRow className={'payment-method-card-content'}>
            <CardGroup>
              <div className="payment-method-card-label">
                {activePaymentMethod[0]?.payment_type === PaymentType.BANK
                  ? 'Account Number'
                  : 'Credit Card Number'}
              </div>
              <div className="payment-method-card-value">
                xxxx-xxxx-xxxx-{activePaymentMethod[0]?.last4}
              </div>
            </CardGroup>
            <CardGroup>
              <div className="payment-method-card-label">
                Listing Fee (incl. tax)
              </div>
              <div className="payment-method-card-value">
                $
                {parseFloat(
                  activePaymentMethod[0]?.order_total_amount
                )?.toFixed(2)}
                {currencyCode?.toLowerCase() === 'ca' ? ' CAD' : null}
              </div>
            </CardGroup>
            <CardGroup>
              <div className="payment-method-card-label">Next Payment Date</div>
              <div className="payment-method-card-value">
                {next_payment_date}
              </div>
            </CardGroup>
          </CardRow>
        </CardBody>
      </CardWrapper>
      {paymentsInfo && paymentsInfo.length > 1 && (
        <div style={{ marginTop: '35px' }}>
          <CardWrapper>
            <CardBody style={{ padding: '32px' }}>
              <CardTitle
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="payment-method-card-title">
                  <div className="">Bank Account</div>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {paymentSettingData.filter(
                    x =>
                      x.payment_type === 'stripe_bank' &&
                      x.last4 === pendingPaymentMethod[0]?.last4 &&
                      x.customer_reference ===
                        pendingPaymentMethod[0].customer_reference
                  ).length > 0 && (
                    <PrimaryButton
                      icon={<IconStatusWarningExclamation />}
                      isIconTrailing
                      text="Verify"
                      onClick={() => setIsVerifyOpen(true)}
                    />
                  )}
                  <SecondaryButton
                    icon={<IconEdit />}
                    isIconTrailing
                    text="Edit"
                    onClick={() => setShowEdit(true)}
                  />
                  <SecondaryButton
                    icon={<IconDelete />}
                    isIconTrailing
                    text="Delete"
                    onClick={() => {
                      setIsDialogOpen(true);
                    }}
                  />
                </div>
              </CardTitle>
              <CardRow
                style={{
                  border: '1px solid',
                  color: 'rgba(196, 198, 200, 1)',
                  marginTop: '10px'
                }}
              />
              <CardRow className={'payment-method-card-content'}>
                <CardGroup>
                  <div className="payment-method-card-label">
                    Account Number
                  </div>
                  <div className="payment-method-card-value">
                    xxxx-xxxx-{pendingPaymentMethod[0]?.last4}
                  </div>
                </CardGroup>
              </CardRow>
              <CardRow style={{ marginTop: '20px' }}>
                <Toast
                  content="To make this your default payment method, you need to verify your Bank Account."
                  id="toast-1"
                  variant={TOAST_VARIANTS.WARNING}
                  visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
                />
              </CardRow>
            </CardBody>
          </CardWrapper>
        </div>
      )}
      {showEdit && (
        <PaymentCheckoutDialog
          solution={
            props.solutions &&
            Array.isArray(props.solutions) &&
            props.solutions[props.solutions.length - 1]
          }
          orgAddress={props.entity.address}
          orgName={props.entity.name}
          userData={props.userData}
          onClose={handleClose}
          isPaymentEdit={true}
          actionBtnText="Continue"
          savedAddress={
            paymentsInfo && paymentsInfo.length > 1
              ? Array.isArray(pendingPaymentMethod) &&
                pendingPaymentMethod[0]?.address
              : Array.isArray(activePaymentMethod) &&
                activePaymentMethod[0]?.address
          }
        />
      )}
      {isVerifyOpen && (
        <div className="my-soln-list-table-link">
          <VerifyAccount
            solnId={solution.id}
            orgId={props.entity.id}
            loggedInEmail={props.userData.email}
            token={props.accessToken}
            stripePriceId={pendingPaymentMethod[0]?.price_id_reference}
            savedSolutionPaymentDetails={{
              last4: pendingPaymentMethod[0]?.last4
            }}
            paymentSettingData={paymentSettingData.filter(
              x =>
                x.payment_type === 'stripe_bank' &&
                x.last4 === pendingPaymentMethod[0]?.last4
            )}
            bankApps={bankApps}
            isPaymentEdit={true}
            setIsVerifyOpen={setIsVerifyOpen}
            isVerifyOpen={isVerifyOpen}
            setPaymentMethodRefresh={setRefresh}
            onPaymentUpdateSuccess={onPaymentUpdateSuccess}
            setShowEdit={setShowEdit}
          />
        </div>
      )}
    </>
  );
};

const CustomEmptyContent = props => {
  const { message = [] } = props;
  return (
    <div className="empty-content-div">
      <img
        alt="Empty Content"
        src={EmptyContentImage}
        style={{ width: '100%', height: '270px' }}
      />
      <div
        className="empty-content-card-message"
        style={{ marginTop: '-15px' }}
      >
        {message.map(line => (
          <div>{line}</div>
        ))}
      </div>
    </div>
  );
};
