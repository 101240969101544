import SolutionSubmissionForm from '../components/solution-submission/SolutionSubmissionForm';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withCommand from './withCommand';

const appSubmissionFragment = gql`
  fragment SolutionSubmissionModel on Solution {
    id
    developerId
    orgId
  }
`;

export const getSolutionQuery = gql`
  query(
    $orgId: String!
    $solutionId: ID!
    $type: String!
    $listingVersion: String
  ) {
    apps(
      orgId: $orgId
      id: $solutionId
      type: $type
      listingVersion: $listingVersion
    ) {
      id
      listingVersion
      apigee_id
      oneToOneUI
      name
      description
      details
      lastModified
      lastModifier
      website
      callbackUrl
      consumerKey
      consumerSecret
      expiresAt
      issuedAt
      registeredOn
      requires {
        id
        name
        displayName
        description
      }
      certificationStatus
      solutiondata {
        id
        listingVersion
        apigee_id
        developerId
        orgId
        developerName
        status
        submittedOn
        lastUpdatedBy
        lastUpdatedOn
        registeredOn
        solutionType
        overview {
          name
          category
          applicationArea
          searchKeywords
          version
          briefDescription
          detailedDescription
          publisherName
          website
          solutionWebsite
          outageNotificationsEmail
          copyright
          privacyPolicyUrl
          termsAndConditionsUrl
          solutionLogo
          videos {
            id
            title
            url
            description
          }
          featuredImage
          galleryImages {
            id
            galleryImage
            imagePositionIndex
          }
        }
        highlights {
          highlight_list {
            id
            description
          }
        }
        support {
          tutorials_and_documentation {
            id
            title
            url
            purpose
          }
          supportMessage
          supportCenterUrl
          phoneNumber
          emailAddress
          supportCommunityUrl
        }
        features {
          id
          title
          description
          featureImage
        }
        plans {
          id
          isTheFreeTrial
          isFreeTrialEnabled
          position
          planName
          planDescription
          freeTrialPeriod
          freeTrialPeriodType
          billingType
          transactionalTypeOptions
          includedTransactionalType
          includedTransactionalFee
          billingFrequency
          basePrice
          includedUnitOfMeasure
          includedUnitQuantity
          additionalUnitPrice
          additionalUnitOfMeasure
          features {
            id
            title
            description
            featureImage
          }
        }
        comments {
          userId
          commentDate
          commentText
          action
        }
        provisioning_interface {
          endpointUrl
          redirectURL
          provisioningtype
          entityID
          subscriptionID
        }
        subscription_notifications {
          email
          endpointUrl
          apiNotification
        }
        registration_details {
          api_details {
            id
            name
            displayName
            description
            isOptional
            apiType
            asyncIntegrationId
            providers {
              id
              entityId
              name
            }
          }
          apiKey
          apiSecret
          keyIssuedOn
          keyExpiresOn
          callbackUrl
        }
        solution_visibility {
          solutionVisibilityType
          privateSolutionUsers
        }
        app_subscriptions {
          appSubscriptionsType
        }
        partner_program_registration_details {
          partner_api_details {
            id
            applicationName
            description
            dataProvider
          }
        }
        address {
          street_address
          city
          country
          countrySubDivisionCode
          postalCode
        }
        payment_info {
          paymentMode
          paymentOption {
            stripe
            cdk
          }
        }
        listingVersionUpdates {
          description
          updates {
            id
            updateText
          }
        }
      }
    }
  }
`;

export const sent_status_email = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $solutionName: String
    $solutionReviewComments: String
    $firstName: String!
    $lastName: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionReviewComments: $solutionReviewComments
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const query = gql`
  mutation($formFields: SubmitSolutionCmd) {
    submitSolution(command: $formFields) {
      ...SolutionSubmissionModel
    }
  }
  ${appSubmissionFragment}
`;

//Changes this mutation query
export const recallQuery = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String
    $lastUpdatedBy: String
    $lastUpdatedOn: String
    $comments: [CommentCmd]
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
  ) {
    updateSolutionStatus(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        lastUpdatedBy: $lastUpdatedBy
        lastUpdatedOn: $lastUpdatedOn
        comments: $comments
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
      }
    ) {
      id
      listingVersion
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  dataRefetch: () => data.refetch(),
  solution: data.apps,
  loading: data.loading
});

export const afterSubmit = () => {
  //  alert("Details submitted for review successfully");
  //  alert("Details submitted for review successfully");
};

export default compose(
  graphql(getSolutionQuery, {
    props: mapDataToProps,
    options: ({
      entity,
      solutionId,
      solutionType,
      solutionListingVersion
    }) => ({
      variables: {
        orgId: entity.id,
        solutionId: solutionId,
        type: solutionType,
        listingVersion: solutionListingVersion
      }
    })
  }),
  compose(
    graphql(query, {
      props: ({ mutate }) => ({
        submit: formfields =>
          mutate({
            variables: {
              formFields: formfields
            },
            update: afterSubmit()
          })
      })
    }),
    withCommand({ name: 'submit' })
  ),
  compose(
    graphql(recallQuery, {
      props: ({ mutate }) => ({
        changeStatus: (
          id,
          listingVersion,
          status,
          lastUpdatedBy,
          lastUpdatedOn,
          comments,
          developerEmail,
          orgId,
          appName,
          attributeName,
          attributeValue
        ) =>
          mutate({
            variables: {
              id: id,
              listingVersion: listingVersion,
              status: status,
              lastUpdatedBy: lastUpdatedBy,
              lastUpdatedOn: lastUpdatedOn,
              comments: comments,
              developerEmail: developerEmail,
              orgId: orgId,
              appName: appName,
              attributeName: attributeName,
              attributeValue: attributeValue
            },
            update: afterSubmit(id)
          })
      })
    }),
    withCommand({ name: 'changeStatus' })
  ),
  compose(
    graphql(sent_status_email, {
      props: ({ mutate }) => ({
        sendEmail: (
          emailAddress,
          emailSubject,
          emailType,
          solutionName,
          solutionReviewComments,
          firstName,
          lastName
        ) =>
          mutate({
            variables: {
              emailAddress: emailAddress,
              emailSubject: emailSubject,
              emailType: emailType,
              solutionName: solutionName,
              solutionReviewComments: solutionReviewComments,
              firstName: firstName,
              lastName: lastName
            }
          })
      })
    }),
    withCommand({ name: 'sendEmail' })
  )
)(SolutionSubmissionForm);
