import FortellisConstants from '../../common/FortellisConstants';
export class MenuDataConfig {
  static MenuData = {
    contents: [
      {
        title: 'Version Updates',
        steps: [
          {
            id: 'listingVersionUpdates',
            label: "What's New",
            type: 'simple'
          }
        ],
        showIf: function(solution) {
          return (
            solution.solutiondata.listingVersion &&
            solution.solutiondata.listingVersion >
              FortellisConstants.LISTING_VERSION.START
          );
        }
      },
      {
        title: 'App Details',
        steps: [
          {
            id: 'overview',
            label: 'Overview',
            type: 'simple'
          },
          { id: 'highlights', label: 'Highlights', type: 'simple' },
          {
            id: 'features',
            label: 'Features',
            type: 'expansion',
            nestedSteps: [
              {
                type: 'array',
                dataFrom: 'features'
              }
            ]
          },
          {
            id: 'support',
            label: 'Support',
            type: 'simple'
          },
          {
            id: 'termsOfUse',
            label: 'Terms Of Use',
            type: 'simple',
            showIf: function(solution) {
              return (
                solution.solutiondata &&
                solution.solutiondata.activateOnly === false
              );
            }
          }
        ]
      },
      {
        title: 'Pricing Details',
        steps: [
          {
            id: 'plans',
            label: 'Plans',
            type: 'expansion',
            nestedSteps: [
              {
                type: 'array',
                dataFrom: 'plans'
              }
            ]
          }
        ],
        showIf: function(solution) {
          return (
            solution.solutiondata &&
            solution.solutiondata.activateOnly === false
          );
        }
      },
      {
        title: 'Registration Details',
        steps: [
          {
            id: 'registration_details',
            label: 'API Details',
            type: 'simple',
            showIf: function(solution = {}) {
              return (
                solution.solutiondata &&
                solution.solutiondata.solutionType ===
                  FortellisConstants.FORTELLIS
              );
            }
          },
          {
            id: 'partner_program_registration_details',
            label: 'API Integration',
            type: 'simple',
            showIf: function(solution = {}) {
              return (
                solution.solutiondata &&
                solution.solutiondata.solutionType ===
                  FortellisConstants.PARTNER_PROGRAM
              );
            }
          }
        ]
      },
      {
        title: 'Provisioning',
        steps: [
          {
            id: 'subscription_notifications',
            label: 'Subscription Notifications',
            type: 'simple'
          },
          {
            id: 'provisioning_interface',
            label: 'Provisioning Options',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Settings',
        steps: [
          {
            id: 'solution_visibility',
            label: 'Visibility',
            type: 'simple'
          },
          {
            id: 'app_subscriptions',
            label: 'App Activation',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Additional Details',
        steps: [
          {
            id: 'solution_notes',
            label: 'App Notes',
            type: 'simple'
          },
          {
            id: 'comments',
            label: 'Status History',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Admin Controls',
        steps: [
          {
            id: 'contract_notifications',
            label: 'Contract Notifications',
            type: 'simple'
          },
          {
            id: 'payment_info',
            label: 'Payment Methods',
            type: 'simple'
          },
          {
            id: 'un_authorized_signon',
            label: 'Authorized SignOn',
            type: 'simple'
          },
          {
            id: 'subscription_management',
            label: 'Subscription Enablement',
            type: 'simple'
          },
          {
            id: 'data_rights',
            label: 'Data Rights Enablement',
            type: 'simple'
          },
          // {
          //   id: 'additional_info',
          //   label: 'Additional Info',
          //   type: 'simple'
          // },
          {
            id: 'additional_info',
            label: 'Catalog & Salesforce Setup',
            type: 'simple'
          },
          {
            id: 'custom_consent',
            label: 'Dealer Consent Form',
            type: 'simple'
          },
          {
            id: 'worksWithIds',
            label: 'Integrations',
            type: 'simple'
          },
          {
            id: 'featured_app',
            label: 'Featured App',
            type: 'simple'
          },
          {
            id: 'appPublishingFee',
            label: 'Marketplace Listing Fee',
            type: 'simple'
          }
        ]
      }
    ],
    activeMenuId: 'overview',
    nestedActiveMenuId: 'overview',
    expandedMenuId: 'plans',
    features: [],
    plans: []
  };
}

export default MenuDataConfig;
