import SolutionReviewForm from '../components/admin-account/solution-moderation/SolutionReviewForm';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withCommand from './withCommand';
import { sent_status_email } from './SolutionSubmissionFormContainer';

export const getSolutionQuery = gql`
  query($solutionId: ID, $listingVersion: String) {
    solutionDetails(id: $solutionId, listingVersion: $listingVersion) {
      id
      listingVersion
      apigee_id
      developerId
      developerName
      activateOnly
      developerAgreement
      dnpAppName
      orgId
      status
      submittedOn
      lastUpdatedBy
      lastUpdatedOn
      registeredOn
      solutionType
      oneToOneUI
      solution_visibility {
        solutionVisibilityType
        privateSolutionUsers
      }
      app_subscriptions {
        appSubscriptionsType
      }
      overview {
        name
        domain
        category
        applicationArea
        searchKeywords
        version
        briefDescription
        detailedDescription
        publisherName
        website
        solutionWebsite
        outageNotificationsEmail
        copyright
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
        videos {
          id
          title
          url
          description
        }
        featuredImage
        galleryImages {
          id
          galleryImage
          imagePositionIndex
        }
      }
      highlights {
        highlight_list {
          id
          description
        }
      }
      provisioning_interface {
        endpointUrl
        redirectURL
        provisioningtype
        entityID
        subscriptionID
      }
      subscription_notifications {
        email
        endpointUrl
        apiNotification
      }
      support {
        tutorials_and_documentation {
          id
          title
          url
          purpose
        }
        supportMessage
        supportCenterUrl
        phoneNumber
        emailAddress
        supportCommunityUrl
      }
      worksWithIds
      appPublishingFee
      features {
        id
        title
        description
        featureImage
      }

      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      subscription_management {
        allowSubscriptions
      }
      data_rights {
        captureDataRightsConsent
      }
      contract_notifications {
        contractNotificationsType
      }
      payment_info {
        paymentMode
        paymentOption {
          stripe
          cdk
        }
      }
      un_authorized_signon {
        isEnabled
        signOnRole
      }
      additional_info {
        catalog_number
        is_vision_case_required
        vision_case_queue
        uninstall_queue
      }
      custom_consent {
        customConsentForm
        fileName
        selectedApis
      }
      registration_details {
        api_details {
          id
          name
          displayName
          description
          isOptional
          apiType
          asyncIntegrationId
          providers {
            id
            entityId
            name
          }
        }
        apiKey
        apiSecret
        keyIssuedOn
        keyExpiresOn
        callbackUrl
      }
      partner_program_registration_details {
        partner_api_details {
          id
          applicationName
          description
          dataProvider
        }
      }
      address {
        street_address
        city
        country
        countrySubDivisionCode
        postalCode
      }
      listingVersionUpdates {
        description
        updates {
          id
          updateText
        }
      }
      isFeatured
      stripePriceId
      listingFeeHistory {
        userEmail
        prevListingFee
        newListingFee
        updatedOn
      }
    }
  }
`;

export const updateSolutionStatusQuery = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String
    $lastUpdatedBy: String
    $lastUpdatedOn: String
    $comments: [CommentCmd]
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
  ) {
    updateSolutionStatus(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        lastUpdatedBy: $lastUpdatedBy
        lastUpdatedOn: $lastUpdatedOn
        comments: $comments
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
      }
    ) {
      id
      listingVersion
      status
      error {
        message
      }
    }
  }
`;

export const solution_status_email_csmsg = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $lastName: String
    $solutionName: String
    $solutionId: String
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const mapDataToProps = ({ data }) => {
  return {
    solution:
      (data &&
        data.solutionDetails &&
        data.solutionDetails.length && [
          data.solutionDetails.sort(
            (a, b) => a.listingVersion - b.listingVersion
          )[data.solutionDetails.length - 1]
        ]) ||
      [],
    prevListing:
      (data &&
        data.solutionDetails &&
        data.solutionDetails.length &&
        data.solutionDetails.sort(
          (a, b) => a.listingVersion - b.listingVersion
        )[data.solutionDetails.length - 2]) ||
      null,
    loading: data.loading
  };
};

export default compose(
  graphql(getSolutionQuery, {
    props: mapDataToProps,
    options: ({ solutionId, listingVersion }) => ({
      variables: {
        solutionId: solutionId,
        listingVersion: listingVersion
      }
    })
  }),
  compose(
    graphql(updateSolutionStatusQuery, {
      props: ({ mutate }) => ({
        updateSolutionStatus: (
          id,
          listingVersion,
          status,
          lastUpdatedBy,
          lastUpdatedOn,
          comments,
          developerEmail,
          orgId,
          appName,
          attributeName,
          attributeValue
        ) =>
          mutate({
            variables: {
              id: id,
              listingVersion: listingVersion,
              status: status,
              lastUpdatedBy: lastUpdatedBy,
              lastUpdatedOn: lastUpdatedOn,
              comments: comments,
              developerEmail: developerEmail,
              orgId: orgId,
              appName: appName,
              attributeName: attributeName,
              attributeValue: attributeValue
            }
          })
      })
    }),
    withCommand({ name: 'updateSolutionStatus' })
  ),
  compose(
    graphql(sent_status_email, {
      props: ({ mutate }) => ({
        sendEmail: (
          emailAddress,
          emailSubject,
          emailType,
          solutionName,
          solutionReviewComments,
          firstName,
          lastName
        ) =>
          mutate({
            variables: {
              emailAddress: emailAddress,
              emailSubject: emailSubject,
              emailType: emailType,
              solutionName: solutionName,
              solutionReviewComments: solutionReviewComments,
              firstName: firstName,
              lastName: lastName
            }
          })
      })
    }),
    withCommand({ name: 'sendEmail' })
  ),
  compose(
    graphql(solution_status_email_csmsg, {
      props: ({ mutate }) => ({
        sendEmailCSMG: (
          to,
          emailSubject,
          emailType,
          solutionName,
          solutionId,
          firstName,
          lastName
        ) =>
          mutate({
            variables: {
              to: to,
              emailSubject: emailSubject,
              emailType: emailType,
              firstName: firstName,
              lastName: lastName,
              solutionName: solutionName,
              solutionId: solutionId
            }
          })
      })
    }),
    withCommand({ name: 'sendEmailCSMG' })
  )
)(SolutionReviewForm);
