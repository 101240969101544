import React from 'react';
import PropTypes from 'prop-types';
import Spinner, { DialogSpinnerWithCenteredText } from '../../common/Spinner';
import './SolutionsListForAdmin.scss';
import moment from 'moment';
import { Card, CardText } from '@cdk-uip/react-card';
import EmptyContent from '../../common/images/Empty State 2x.png';
import { Chip } from '@cdk-uip/react-chip';
import { Tooltip } from '@cdk-uip/react-tooltip';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import { Redirect } from 'react-router-dom';
import { Subheading2, Title } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import solutionShape from './solutionShape';
import SolutionStatus from '../../common/SolutionStatus';
import Status from '../../../components/marketplace-account/Status';
import SolutionLogo from '../../common/images/cogs.png';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InsightDashboardItem } from '../../marketplace-account/my-solutions/solution-management/panels/insight-items/InsightDashboardItem';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import { Avatar } from '@cdk-uip/react-avatar';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import { connect } from 'react-redux';
import { getStripeSubscriptionInfo } from '../../../redux/connectedComponents/stripeSubscriptionInfo/stripeSubscriptionInfo.slice';
class SolutionsListForAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.list = [];
  }
  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list) {
      this.list = this.props.list.sort((solution1, solution2) => {
        return (
          new Date(solution2.submittedOn) - new Date(solution1.submittedOn)
        );
      });
      this.setState({
        solutionList: this.list,
        fetchingData: false,
        completeList: this.list
      });
    }
  }

  static propTypes = {
    list: PropTypes.arrayOf(solutionShape).isRequired,
    loading: PropTypes.bool
  };

  state = {
    tabIndex: 2,
    initialize: false,
    redirectToSolution: null,
    solutionListStatus: false,
    solutionList: [],
    fetchingData: true,
    selectedChip: 'Submitted',
    completeList: [],
    searchText: ''
  };

  redirecter = async (solutionId, orgId, listingVersion, status) => {
    this.props.getStripeSubscriptionInfo(
      solutionId,
      orgId,
      this.props.accessToken
    );
    if (
      status === 'Submitted' ||
      status === 'In Review' ||
      status === 'Approved'
    ) {
      await this.setState({
        redirectToSolution: {
          solutionId: solutionId
        }
      });
    } else {
      await this.setState({
        redirectToSolution: {
          solutionId: solutionId,
          listingVersion: listingVersion
        }
      });
    }
  };

  startReview = () => {};

  getSolutionListByStatus = async solutionStatus => {
    const client = getApolloClient();
    let queryResponse = await client.query({
      query: gql`
        query($status: String) {
          solutionList(status: $status) {
            id
            listingVersion
            status
            submittedOn
            developerName
            developerId
            dnpAppName
            solutionType
            orgId
            overview {
              name
              version
              solutionLogo
            }
            comments {
              commentText
            }
            subscription_management {
              allowSubscriptions
            }
          }
        }
      `,
      variables: {
        status: solutionStatus
      }
    });
    let responseSolutionList = queryResponse.data.solutionList;
    let sortedSolutionList = responseSolutionList.sort(function(
      solution1,
      solution2
    ) {
      return new Date(solution2.submittedOn) - new Date(solution1.submittedOn);
    });
    if (this.state.searchText.length > 0) {
      var filteredList = sortedSolutionList.filter(solution => {
        return solution.overview.name
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      });
      this.setState({
        solutionList: filteredList,
        fetchingData: false,
        completeList: sortedSolutionList
      });
    } else {
      this.setState({
        solutionList: sortedSolutionList,
        fetchingData: false,
        completeList: sortedSolutionList
      });
    }
  };

  onClickChipHandler = (event, solutionStatus, tabIndexValue) => {
    event.preventDefault();
    this.getSolutionListByStatus(solutionStatus);
    this.setState({
      tabIndex: tabIndexValue,
      fetchingData: true,
      selectedChip: solutionStatus
    });
  };

  onSearchTextChange(event) {
    var filteredList = this.state.completeList.filter(solution => {
      return solution?.overview?.name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    this.setState({
      solutionList: filteredList,
      searchText: event.target.value
    });
  }

  render() {
    var { intl, loading, solutionCount = {} } = this.props;
    let listToDisplay = [];

    if (this.state.redirectToSolution) {
      return (
        <Redirect
          to={{
            pathname: `/admin/solutions`,
            state: {
              solutionId: this.state.redirectToSolution.solutionId,
              listingVersion: this.state.redirectToSolution.listingVersion
            }
          }}
          activeClassName=""
        />
      );
    }

    if (this.state.solutionList.length != 0) {
      listToDisplay = this.state.solutionList;
    }

    if (this.state.fetchingData) {
      listToDisplay = [];
    }

    return (
      <>
        <div className="component-content">
          <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="myaccount-component-content">
              <LayoutGrid className="fm-page-component-grid">
                <LayoutGridCell
                  span={12}
                  className="fm-page-content-title-cell"
                >
                  <a
                    className="redirect-to-admin"
                    href={environmentURLs.adminAccount}
                  >
                    <span className="redirect-icon">
                      <i className="material-icons">keyboard_arrow_left</i>
                    </span>
                    <span className="redirect-to-admin-text">Back to Home</span>
                  </a>
                  <Title className="fm-page-content-title">
                    <span>
                      <FormattedMessage
                        id="SolutionsListForAdmin.title"
                        defaultMessage="Apps"
                      />
                    </span>
                  </Title>
                  <Subheading2 className="fm-page-content-title-note">
                    <FormattedMessage
                      id="SolutionsListForAdmin.description"
                      defaultMessage="The apps and other apps which developed to offer in the Fortellis Marketplace. You can review information for apps in the list."
                    />
                  </Subheading2>
                </LayoutGridCell>
              </LayoutGrid>
              <div className="solutioncount-dashboard">
                <InsightDashboardItem
                  className="insights-dashboard-item-left"
                  label={
                    <FormattedMessage
                      id="SolutionCount.Dashboard.submitted"
                      defaultMessage="Submitted"
                    />
                  }
                  value={solutionCount.submitted}
                />
                <InsightDashboardItem
                  label={
                    <FormattedMessage
                      id="SolutionCount.Dashboard.inProgress"
                      defaultMessage="In Progress"
                    />
                  }
                  value={solutionCount.inProgress}
                />
                <InsightDashboardItem
                  className="insights-dashboard-item-right"
                  label={
                    <FormattedMessage
                      id="SolutionCount.Dashboard.published"
                      defaultMessage="Published"
                    />
                  }
                  value={solutionCount.published}
                />
              </div>
            </LayoutGridCell>
            <LayoutGridCell span={12}>
              <div>
                <Card>
                  <CardText className="lead-card-text">
                    <div className="status-card-chips-div">
                      <Chip
                        className={
                          this.state.tabIndex === 2
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.SUBMITTED,
                            2
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipSubmitted"
                          defaultMessage="Submitted"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 5
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.IN_REVIEW,
                            5
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipInReview"
                          defaultMessage="In Review"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 3
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.REJECTED,
                            3
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipRejected"
                          defaultMessage="Rejected"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 6
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.APPROVED,
                            6
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipApproved"
                          defaultMessage="Approved"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 4
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(e, SolutionStatus.REVOKED, 4);
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipRevoked"
                          defaultMessage="Revoked"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 7
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.PUBLISHED,
                            7
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipPublished"
                          defaultMessage="Published"
                        />
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 8
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          this.onClickChipHandler(
                            e,
                            SolutionStatus.WITHDRAWN,
                            8
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionsListForAdmin.chipWithdrawn"
                          defaultMessage="Withdrawn"
                        />
                      </Chip>

                      <div className="solutions-search-box-div">
                        <input
                          type="text"
                          value={this.state.searchText}
                          className="solution-search-input"
                          placeholder={
                            'Search in ' +
                            this.state.selectedChip +
                            ' apps by Name'
                          }
                          onChange={e => this.onSearchTextChange(e)}
                          disabled={this.state.fetchingData}
                        />

                        <Avatar className="search-icon-button">
                          <i className="material-icons">search</i>
                        </Avatar>
                      </div>
                    </div>
                    {loading ? (
                      <div>
                        <Spinner />
                        <div style={{ textAlign: 'center' }}>
                          <strong>Loading...</strong>
                        </div>
                      </div>
                    ) : (
                      <LayoutGridCell span={12}>
                        {listToDisplay && listToDisplay.length > 0 ? (
                          <div className="admin-lead-table">
                            <DataTable className="table-list">
                              <DataTableHeader>
                                <DataTableRow>
                                  <DataTableHeaderCell
                                    nonNumeric
                                    style={{
                                      textAlign: 'left',
                                      padding: '0 5px 12px'
                                    }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.column1"
                                      defaultMessage="App Name"
                                    />
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    style={{ textAlign: 'left' }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.column2"
                                      defaultMessage="App Version"
                                    />
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    style={{
                                      textAlign: 'left',
                                      padding: '0 5px 12px'
                                    }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.column3"
                                      defaultMessage="Listing Version"
                                    />
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    style={{
                                      textAlign: 'left',
                                      padding: '0 5px 12px'
                                    }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.column4"
                                      defaultMessage="Submitted On"
                                    />
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    style={{
                                      textAlign: 'left',
                                      padding: '0 5px 12px'
                                    }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.columnDeveloperName"
                                      defaultMessage="Developer Name"
                                    />
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    style={{
                                      padding: '0 35px 12px'
                                    }}
                                  >
                                    <FormattedMessage
                                      id="SolutionsListForAdmin.column5"
                                      defaultMessage="Status"
                                    />
                                  </DataTableHeaderCell>
                                </DataTableRow>
                              </DataTableHeader>
                              <DataTableBody>
                                {listToDisplay.map(
                                  (solution, solutionIndex) => {
                                    return solution && solution.overview ? (
                                      <DataTableRow
                                        className="table-text fm-solution-row"
                                        key={solutionIndex}
                                      >
                                        <DataTableCell
                                          className="fm-expansion-panel-title"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 0px'
                                          }}
                                          onClick={() =>
                                            this.redirecter(
                                              solution.id,
                                              solution.orgId,
                                              solution.listingVersion,
                                              solution.status
                                            )
                                          }
                                        >
                                          <div className="app-name-holder">
                                            <div className="app-name-image-holder">
                                              {solution.overview
                                                .solutionLogo ? (
                                                <img
                                                  alt={intl.formatMessage({
                                                    id:
                                                      'SolutionsListForAdmin.img.alt',
                                                    defaultMessage: 'App Logo'
                                                  })}
                                                  className="fm-expansion-panel-title-logo"
                                                  src={`${
                                                    solution.overview
                                                      .solutionLogo
                                                  }?${Math.random()}`}
                                                />
                                              ) : (
                                                <img
                                                  alt={intl.formatMessage({
                                                    id:
                                                      'SolutionsListForAdmin.img.alt',
                                                    defaultMessage: 'App Logo'
                                                  })}
                                                  className="fm-expansion-panel-title-logo"
                                                  src={SolutionLogo}
                                                />
                                              )}
                                            </div>
                                            <div className="mp-dnp-app-name-container">
                                              <div className="app-name-caption-holder">
                                                {solution.overview.name}
                                              </div>
                                              <div className="solution-common-header-subtitles">
                                                On Developer Account:{' '}
                                                <span className="solution-common-header-subtitles-value">
                                                  {solution &&
                                                  solution.dnpAppName
                                                    ? solution.dnpAppName
                                                    : solution.overview.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </DataTableCell>

                                        <DataTableCell
                                          className="review-solution-version"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 18px'
                                          }}
                                          onClick={() =>
                                            this.redirecter(
                                              solution.id,
                                              solution.orgId,
                                              solution.listingVersion,
                                              solution.status
                                            )
                                          }
                                        >
                                          <div>
                                            {solution.overview.version
                                              ? solution.overview.version
                                              : '-'}
                                          </div>
                                        </DataTableCell>
                                        <DataTableCell
                                          className="review-listing-version"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 18px'
                                          }}
                                          onClick={() =>
                                            this.redirecter(
                                              solution.id,
                                              solution.orgId,
                                              solution.listingVersion,
                                              solution.status
                                            )
                                          }
                                        >
                                          {solution.listingVersion
                                            ? solution.listingVersion
                                            : '-'}
                                        </DataTableCell>
                                        <DataTableCell
                                          className="review-solution-date"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 18px'
                                          }}
                                          onClick={() =>
                                            this.redirecter(
                                              solution.id,
                                              solution.orgId,
                                              solution.listingVersion,
                                              solution.status
                                            )
                                          }
                                        >
                                          {moment(solution.submittedOn).format(
                                            'DD MMM YYYY'
                                          )}
                                        </DataTableCell>

                                        <DataTableCell
                                          className="review-solution-developername"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 18px'
                                          }}
                                        >
                                          <a
                                            href={`mailto:${
                                              solution.developerId
                                            }`}
                                          >
                                            {solution.developerName || ''}
                                          </a>
                                          <div>{solution.developerId}</div>
                                        </DataTableCell>

                                        <DataTableCell
                                          className="fm-expansion-panel-status"
                                          style={{
                                            textAlign: 'left',
                                            padding: '0 5px 18px'
                                          }}
                                          onClick={() =>
                                            this.redirecter(
                                              solution.id,
                                              solution.orgId,
                                              solution.listingVersion,
                                              solution.status
                                            )
                                          }
                                        >
                                          <Status
                                            icon="lens"
                                            status={solution.status}
                                          />

                                          {solution &&
                                            solution.status ===
                                              SolutionStatus.REJECTED && (
                                              <div
                                                id={`tooltip_${solution.id}`}
                                                className="status-info"
                                              >
                                                <FormattedMessage
                                                  id="SolutionsListForAdmin.solutionStatus3"
                                                  defaultMessage="View Errors"
                                                />
                                              </div>
                                            )}

                                          {solution &&
                                            solution.status ===
                                              SolutionStatus.REJECTED && (
                                              <Tooltip
                                                className="view-error-tooltip"
                                                htmlFor={`tooltip_${
                                                  solution.id
                                                }`}
                                              >
                                                <span>
                                                  {solution.comments &&
                                                  solution.comments.length >
                                                    0 ? (
                                                    solution.comments[
                                                      solution.comments.length -
                                                        1
                                                    ].commentText
                                                  ) : (
                                                    <FormattedMessage
                                                      id="SolutionsListForAdmin.commentText"
                                                      defaultText="No Comments"
                                                    />
                                                  )}
                                                </span>
                                              </Tooltip>
                                            )}
                                        </DataTableCell>
                                      </DataTableRow>
                                    ) : (
                                      false
                                    );
                                  }
                                )}
                              </DataTableBody>
                            </DataTable>
                          </div>
                        ) : (
                          <div className="empty-content-div">
                            {this.state.fetchingData ? (
                              <div>
                                <Spinner />
                                <div style={{ textAlign: 'center' }}>
                                  <strong>Loading...</strong>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <img
                                  alt={intl.formatMessage({
                                    id:
                                      'SolutionListForAdmin.emptyContentImg.alt',
                                    defaultMessage: 'Empty Content'
                                  })}
                                  className="empty-content-card-img"
                                  src={EmptyContent}
                                />
                                <div className="empty-content-card-message">
                                  <FormattedMessage
                                    id="SolutionListForAdmin.emptyContentMessage"
                                    defaultMessage="No apps in selected category, Check back soon!."
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </LayoutGridCell>
                    )}
                  </CardText>
                </Card>
              </div>
            </LayoutGridCell>
          </LayoutGrid>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const { data } = state.stripeSubscriptionData;
  return {
    stripeSubscriptionData: data.stripeSubscriptionData
  };
};
const mapDispatchToProps = dispatch => ({
  getStripeSubscriptionInfo: (id, orgId, token) =>
    dispatch(getStripeSubscriptionInfo({ id: id, orgId: orgId, token: token }))
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SolutionsListForAdmin)
);
